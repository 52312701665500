import { useRef, useState } from "react";
import "./portofoliu.scss"
import {motion, useScroll, useSpring, useTransform} from "framer-motion"



const items =[
    {
    id:1,
    title:"Calculator",
    img:["./calculator.png"],
    desc:"The first project represents a computer that can do all the basic mathematical operations.",
},
{
    id:2,
    title:"Exchange",
    img:["./exchange.png"],
    desc:"The second project is an exchange connected to an api to be able to recognize and process all currency exchanges."
},
{
    id:3,
    title:"Game",
    img:["./Game.png"],
    desc:"The third project is a game between two players, You have to connect 4 dots to win before your opponent.",

},
{
    id:4,
    title:"Password-Generator",
    img:["./password.png"],
    desc:"The fourth project is a random password generator, depending on what is selected as the difficulty.",

},
{
    id:5,
    title:"Todo-List",
    img:["./todo.png"],
    desc:"The fifth project is a way to add lists by the user and to be deleted at his pleasure.",

},
{
    id:6,
    title:"Advanced todo list",
    img:["./advans-todo-list.png"],
    desc:"The sixth project is an improved way to add lists by the user, to delete projects or entire lists, to move projects from one side to another and to create new work columns.",

},
{
    id:7,
    title:"Shop",
    img:["./shop-1.png","./shop-2.png","./shop-3.png","./shop-4.png","./shop-5.png","./shop-6.png","./shop-7.png","./shop-8.png","./shop-9.png","./shop-10.png","./shop-11.png","./shop-12.png",],
    desc:"This project will be one of the most complex, because it will be an entire site that will contain methods of payment, logging, displaying and filtering products, a project that will contain both frontend and backend concepts.",

},

];
const Single = ({ item }) => {
    const ref = useRef();
    const [activeDot, setActiveDot] = useState(0); 
  
    const { scrollYProgress } = useScroll({ target: ref });
    const y = useTransform(scrollYProgress, [0, 1], [-300, 300]);
  
    const handleDotClick = (index) => {
      setActiveDot(index); 
    };
  
    return (
      <section>
        <div className="container">
          <div className="pozitionare">
            <div className="imageContainer" ref={ref}>
       
              {Array.isArray(item.img) && (
                item.img.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`Project ${item.id} image ${index + 1}`}
                    style={{ display: activeDot === index ? 'block' : 'none' }}
                  />
                ))
              )}
              <p/>
                {Array.isArray(item.img) && (
              <div className="dots-wrapper">
                {item.img.map((_, index) => (
                  <button
                    key={index}
                    className={index === activeDot ? 'dot active' : 'dot'}
                    onClick={() => handleDotClick(index)} 
                  ></button>
                ))}
              </div>
            )}
            </div>
           
          
            <motion.div style={{ y }} className="textContainer">
              <h2>{item.title}</h2>
              <p>{item.desc}</p>
            </motion.div>
          </div>
        </div>
      </section>
    );
  };
  

const Portofoliu = ({}) => {

    const ref = useRef();

    const{scrollYProgres}=useScroll({target:ref, offset:["end end","start start"],})

const scaleX= useSpring(scrollYProgres,{
    stiffness:100,
    damping:30,
});

  return (
    <div className="portofoliu" ref={ref}>
        <div className="progres">
            <h1>Featured Works</h1>
            <motion.div style={{scaleX}}className="progresBar"></motion.div>
        </div>
{items.map(item=>(
    <Single item={item}key={item.id}/>
))}


    </div>
  )   
}
export default Portofoliu