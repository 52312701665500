import "./servici.scss"
import { useRef } from "react"
import {motion, useInView} from"framer-motion"

const variants={
    initial:{
        x:-100,
        y:10,
        opacity:0,
    },
    animate:{
        x:0,
        opacity:1,
        y:0,
        transition:{
         duration:1,
            staggerChildren:0.1,

        }
    }
}
const Servici = () => {

    const ref = useRef();
    const isInView= useInView(ref,{margin:"-100px"});
  return (
    <motion.div className="servici" variants={variants} initial="initial" ref={ref} animate={isInView && "animate"}>
      <motion.div className="textContainer" variants={variants}>
        <motion.p>I focus on helping your business grow <br/> and move forward</motion.p>
        <hr/>
      </motion.div>
      <motion.div className="titleContainer" variants={variants} >
        <div className="title"><img src="./About.png" alt="About-img"/>
        <h1><b>Many</b> Skills</h1></div>
       <div className="title"><h1><b>For Your</b> Business.</h1></div> 
      </motion.div>
      <motion.div className="listContainer" variants={variants}>

        <motion.div className="box" whileHover={{background:"lightgray",color:"black"}}><h2>SDA Academy</h2>
<img src="SDA.png"alt="Progect img"/>
        <p>Design Patterns & Good Practices,
Advanced Features - HTML and CSS,
Advanced elements of JavaScript
</p>
       </motion.div>


        <motion.div className="box" whileHover={{background:"lightgray",color:"black"}}><h2>Udemy</h2>
        <img src="Udemy.png"alt="Progect img"/>
        <p>Introduction to OOP,
Code - Classes,
Typecasting,
Abstract Classes,
Lambda Expressions,
Nested and Inner Classes</p>
        </motion.div>

        <motion.div className="box" whileHover={{background:"lightgray",color:"black"}}><h2>Hyperion University</h2>
        <img src="Hyperion.jpg"alt="Progect img"/>
        <p>Mathematical analysis,
Computational logic,
Database,
Microprocessor systems,
Data structures and algorithms,
Optimization techniques.
</p>
        </motion.div>
      
      

      </motion.div>
    </motion.div>
  )
}

export default Servici
